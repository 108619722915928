@import '../node_modules/semantic-ui-css/semantic.min.css';
@import url('https://fonts.googleapis.com/css?family=Merriweather:regular,light,bold&subset=latin,latin-ext');

@font-face {
  font-family: 'RobotoRegular';
  src: url('./Assets/RobotoRegular.ttf')  format('truetype');
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-content: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ui.dimmer{
  background-color: rgba(0,0,0,0.90);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.HeaderLogo{
  margin-bottom: 0px !important;
  text-align: center !important;
  img{
    height: 100px;
  }
}

.TopMenu{
  margin-top: 0px !important;
  border-radius: 0px !important;
  border-bottom: 4px #1971b9 solid !important;
  font-family: 'RobotoRegular' !important;
  font-size: 24px !important;
}
.ui.buttons .or:before {
  background-color: #111 !important;
  color: #FFF;
}
.MainContent{
  // display: flex;
  flex-grow: 3;
  background: url('./Assets/FondoProcasur.jpeg');
  background-position: center center;
  background-size: cover;
  // overflow: hidden !important;
  &--Library{
    background: #FFF;
    padding: 0px 24px;
    padding-top: 32px;
    text-align: left;
    margin-bottom: 32px;
    .list{
      margin-top: 32px;
    }
  }
  iframe {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 205.5px);
    min-height: calc(100vh - 205.5px);
  }
  .CustomEvaluationHook{
    width: 100%;
    min-width: 100%;
    background: #FFF;
    // background: linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.939093137254902) 100%);
    .ui.grid{
      background: none;
      padding-top: 0px;
      margin-top: 1px;
      .HeaderRow.row{
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 350px;
        background: url('https://procasur.biggroup.cl/files/Tarjeta_5/assets/6JhTWXsrHSxj2cAp_4vqSjv_7HuxC4OW1.png');
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        .column{
          width: 100%;
          height: 100%;
          justify-content: center;
          flex-direction: column;
          display: flex;
          background: rgba(0,0,0,0.25);
          color: #FFF;
          font-size: 175%;
          text-shadow: #000 1px 1px 0px;
          h1{
            text-transform: uppercase;
            font-size: 200%;
          }
        }
      }
    }
    .ui.form{
      margin: 0px 0px;
      .six{
        padding-left: 48px;
      }
      .ten{
        padding-right: 48px;
      }
    }
  }
  .six.wide.column{
    padding-right: 64px;
    video {
      // object-fit: cover;
    }
  }
  .ui.grid{
    background: linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.939093137254902) 100%);
    display: flex;
    height: calc(100vh - 205px);
    flex-grow: 100;
    margin-top: 0px;
    padding-top: 48px;
    .row-first-child{
      padding-left: 20vw !important;
    }
    & > .row:last-child{
      padding-bottom: 80px;
      align-items: flex-end;
    }
  }

  .CourseSelectorContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    .ui.card{
      margin: 48px 24px;
      width: 25%;
      .image
      {
        background: #f2f7fa;
        img {
          max-height: 185px;
          object-fit: contain;
        }
      }
    }
  }
  
  .Selector{
    background: rgba(0,0,0,0.0) !important;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 0px !important;
    margin: 0px !important;
    *:not(i) {
      font-family: 'RobotoRegular' !important;
      font-size: 18px;
    }
    i{
      font-size: 60px !important;
      margin-bottom: 24px !important;
    }
    button{
      margin-top: 13px;
    }
    .ui.segment{
      & + .ui.segment {
        margin-top: 0px;
        // margin: 48px 16px;
      }
      // margin: 48px 16px;
      max-height: 64px;
      background: rgba(0,0,0,0.0) !important;
    }
  }
  .Bienvenida{
    font-family: 'RobotoRegular';
    font-size: 18px;
    text-align: left;
    line-height: 28px;
    background: none !important;
    text-shadow: #000 1px 1px 10px;
    .BigText{
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 12px;
      float: left;
      width: 100%;
      text-shadow: #000 1px 1px 10px;
    }
  }
  .LoginBox{
    font-family: 'RobotoRegular';
    font-size: 22px;
    width: 45vw;
    margin-left: 5vw;
    min-width: 500px;
    background: rgba(0,0,0,0.65) !important;
    text-align: left;
    line-height: 26px;
    .ui.form{
      margin-top: 16px;
      .field{
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
    .FormLogin{
      display: flex !important;
    }
  }
}
.row.HeaderRow{
  .column{
    max-width: 100vw;
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}
.MerriweatherBox{
  *:not(i),&:not(i){
    font-family: 'Merriweather', sans-serif !important;
    // font-weight: 300 !important;
    font-size: 17px;
  }
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  .column{
    width: 70vw;
    max-width: 70vw;
    &:first-child{
      padding-top: 48px;
    }
    .row{
      text-align: left;
      &-html{
        h1, h2, h3, h4, h5, h6 {
          font-family: 'Lato', sans-serif !important;
          font-weight: bold;
          border-left: 8px solid #CCC;
          padding-left: 8px;
          margin-bottom: 16px;
        }
        h1{ font-size: 28px; }
        h3{ font-size: 23px; }
        h6{ font-size: 20px; }
        
        margin-bottom: 32px;
      }
      &-line,&-multiline{
        margin-bottom: 16px;
        input, textarea{
          background: rgba(76, 175, 80, 0.07);
          border-color: rgba(76, 175, 80, 0.7);
        }
      }
      &-sendButton{
        text-align: right;
        margin-top: 48px;
        .ui.buttons .or:before{
          background-color: #FFF !important;
          color: #111 !important;
        }
      }
    }
    
  }
}

.row-matrix{
  .react-grid-Row{
    .react-grid-Cell:first-child{
      background: #f9f9f9 !important;
      .react-grid-Cell__value div{
        font-weight: bold !important;
      }
    }
  }
  .react-grid-HeaderCell{
    * {
      font-weight: bold !important;
    }
  }
  margin-bottom: 24px;
}

.row-radio{
  margin-bottom: 24px;
}

.row-likert{
  margin-bottom: 24px;
}

.row-conditional{
  margin-bottom: 24px;
  .row-html{
    margin-left: 12px;
    font-size: 80%;
    border-left: 4px #fc0 solid;
    padding-left: 24px;
  }
  .row.row-multiline {
    label {
      margin-left: 12px;
      font-size: 100%;
      padding-left: 24px;
      font-weight: normal;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .negative label {
      border-left: 4px #fc0 solid;
    }
    .positive label {
      border-left: 4px #8bc34a solid;
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  /* CSS */
  .row.MerriweatherBox{
    .column{
      max-width: 95vw !important;
    }
  }  
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
  .row.MerriweatherBox{
    .column{
      max-width: 85vw !important;
    }
  }  
}

.react-grid-Row {
  & .react-grid-Cell__value {
    white-space: break-spaces !important;
  }
}

.ContentType0{
  display: flex;
  align-items: center;
  flex-direction: column;
  .ContainerContentType0{
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    & > .ui.card.tada{
      // flex-grow: unset;
      // width: calc(25% - 64px);
      flex-basis: 27% !important;
      align-items: center;
      margin-left: 32px;
      &:nth-child(3n){
        margin-left: 48px !important;
        margin-right: 16px !important;
      }
      // margin: 32px;
      &:last-child{
        /* flex-basis: 27%;
        margin-left: 37%;
        flex-grow: unset; */
        margin-bottom: 32px;
        image{
          // height: 100px;
        }
      }
    }
  }
}


.disabled input{
  color: #000 !important;
  opacity: 1 !important;
}